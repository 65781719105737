import './App.css';
import { isAndroid, isIOS } from "react-device-detect";
import React, { useEffect } from "react";

const App = () => {
  useEffect(() => {
    if (isAndroid) {
      const url =
        "intent://page/109938327351422?referrer=app_link#Intent;package=com.facebook.katana;scheme=fb;end";

      window.location.replace(url);
    } else if (isIOS) {
      window.location.replace("fb://page/?id=109938327351422");

      setTimeout(() => {
        window.location.replace(
          "https://www.facebook.com/syngenta.vn.hatgiongcaytrong"
        );
      }, 10000);
    } else {
      window.location.replace("https://facebook.com/");
    }
  }, []);

  return (
    <div className="App">
      <div>
        If you have not been automatically redirected, click on the following link:
      </div>
      {isAndroid ? (
        <a href="https://www.facebook.com/syngenta.vn.hatgiongcaytrong">
          Open Android Facebook app
        </a>
      ) : isIOS ? (
        <a href="https://www.facebook.com/syngenta.vn.hatgiongcaytrong">
          Open iOS Facebook app
        </a>
      ) : (
        <a href="https://www.facebook.com/">Open Web app</a>
      )}
    </div>
  );
};

export default App;
